import { DataSourcesContainer, LoginMockModal } from "processhub-components-lib";
import { useState } from "react";

const mockData = [
  {
    name: 'Pi Server',
    status: 'online',
    inputs: [
      {
        key: 'Server name',
        value: 'pi-server-prod01.local'
      },
      {
        key: 'Username',
        value: 'admin_pi'
      },
      {
        key: 'Password',
        value: 'Pi$3rv3r@2025'
      }
    ]
  },
  {
    name: 'Excel',
    status: 'online',
    inputs: [
      {
        key: 'URL/File Path',
        value: `C:\\Users\\JohnDoe\\Documents\\CompanyData\\Reports\\Database2025.xlsx`
      }
    ]
  },
  {
    name: 'Data center expert',
    status: 'warning',
    inputs: [
      {
        key: 'URL',
        value: ''
      },
      {
        key: 'Username',
        value: ''
      },
      {
        key: 'Password',
        value: ''
      }
    ]
  },
  {
    name: 'Oracle Primavera',
    status: 'warning',
    inputs: [
      {
        key: 'Username',
        value: ''
      },
      {
        key: 'Password',
        value: ''
      },
      {
        key: "API",
        value: "Oracle supports SQL, PL/SQL and REST API"
      },
      {
        key: "JDBC/ODBC Drivers",
        value: 'For Java and other applications'
      },
      {
        key: "Oracle Instant Client",
        value: ''
      }
    ]
  },
  {
    name: 'AVEVA',
    status: 'warning',
    inputs: [
      {
        key: 'Username',
        value: ''
      },
      {
        key: 'Password',
        value: ''
      },
      {
        key: "API",
        value: "REST, .NET SDK"
      },
      {
        key: "Connection Details",
        value: "AVEVA supports cloud and on-premises solutions"
      },
      {
        key: "Supported Formats",
        value: "SPF (.spf), P&ID (.dwg, .dxf), 3D Models (.obj, .fbx)"
      },
    ]
  },
];

const DataSources = () => {
  const [loginOpen, setLoginOpen] = useState(false);
  const handleModalClose = () => setLoginOpen(false);

  return (
    <>
      <div className="text max-w-[1400px] mx-auto">
        <DataSourcesContainer data={mockData} btnAction={setLoginOpen} />
      </div>
      {loginOpen &&
        <LoginMockModal handleModalClose={handleModalClose} />
      }
    </>
  );
};

export default DataSources;