import loginBackground from "../../assets/loginBackground.jpg";
import { Link } from "react-router-dom";
import classNames from "utilities/ClassNames";
import ProgressMonitoringLogo from "components/shared/ProgressMonitoringLogo";
import { useCoordinates } from "hooks/useCoordinates";
import DiscreteSlider from "components/widgets/Slider/Slider";
import useUnity from "hooks/UseUnity";

export default function TempDashboard() {
  const { coordinates, setCoordinates } = useCoordinates();
  const { TimelineShown, setTimelineShown } = useUnity();
  const isScaled = false;
  let tjson: any = 'test';
  function addMessageListener() {
    window.vuplex.addEventListener("message", function (event: { data: any }) {
      tjson = event.data;
      const parsedInfo = JSON.parse(tjson);
      const { methodName, message } = parsedInfo;
      if (methodName === 'SetGPS') {
        const coordinates = JSON.parse(message);
        if (coordinates[0] !== 0 && coordinates[1] !== 0) {
          setCoordinates({ lat: coordinates[1], lng: coordinates[0] });
        }
      }
    });
  }
  if (window.vuplex) {
    addMessageListener();
    const message = JSON.stringify({ object: "__S:ReactInputBlocker__", type: "ReactInputBlocker", method: "SetBlockState", arguments: [0] });
    window.vuplex.postMessage(message);
  } else {
    window.addEventListener('someTestMethod', addMessageListener);
  }
  return (
    <div>
      {TimelineShown &&
        <div className="absolute w-full h-max left-0 top-20 flex items-center justify-center">
          <div className="z-30">
            <DiscreteSlider />
          </div>
        </div>}
      <div className="absolute left-0 right-0 top-5 -bottom-10">
        <img src={loginBackground} className="w-full h-full"></img>
        <div className="left-0 top-[42%] absolute w-full">
          <div className="h-max w-max mx-auto">
            <ProgressMonitoringLogo variant="large" />
            {/* <span className={classNames(isScaled ? "text-4xl mt-2" : "text-5xl mt-1", "text-black opacity-75 font-bold block text-center")}>For Next Era Program</span> */}
          </div>
        </div>
      </div>
      <div className={classNames(isScaled ? "w-60 bottom-10 left-6" : "w-40 bottom-0 left-4", "absolute h-20")}>
        {/* <img src={logo}></img> */}
      </div>
      <div className={classNames(isScaled ? "text-2xl mb-4" : "", "absolute bottom-0 left-[45%] flex mb-2")}>
        <Link to={"https://processhub.ai/"} style={{ color: 'darkblue', textDecoration: 'underline' }}>www.processhub.ai</Link>
      </div>
    </div>
  );
}
